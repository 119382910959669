import { CardProps } from './types'
import { AlarmWidget, ElevationWidget, SnoringWidget, TempWidget, VitalsWidget } from 'components/Phantom/_sections/AutopilotBriefer/Cards'

export const getDefaultCards = (metric: boolean): CardProps[] => {
	if (metric) {
		return [TemperatureMetric, Elevation, Snoring, Vitals, Alarm]
	}

	return [Temperature, Elevation, Snoring, Vitals, Alarm]
}

const Temperature: CardProps = {
	supertitle: 'Temperature',
	title: 'Cools or heats to your perfect temperature',
	subtitle: 'Autopilot makes smart temperature adjustments to your Pod, and can cool or warm each side of the bed as low as 55°F and as high as 110°F.',
	cta: {
		text: 'Learn more',
		href: '/pod-cover',
	},
	cardComponent: TempWidget,
	hint: {
		icon: 'TemperatureLight',
		text: 'Sleeping at the right temperature for each sleep stage increases time spent in deep and REM sleep.',
	},
}

const TemperatureMetric: CardProps = {
	supertitle: 'Temperature',
	title: 'Cools or heats to your perfect temperature',
	subtitle: 'Autopilot makes smart temperature adjustments to your Pod, and can cool or warm each side of the bed as low as 12°C and as high as 43°C',
	cta: {
		text: 'Learn more',
		href: '/pod-cover',
	},
	cardComponent: TempWidget,
	hint: {
		icon: 'TemperatureLight',
		text: 'Sleeping at the right temperature for each sleep stage increases time spent in deep and REM sleep.',
	},
}

const Vitals: CardProps = {
	supertitle: 'Vitals',
	title: 'Tracks your sleep and health vitals',
	subtitle:
		'The Pod tracks your sleep and vital signs, without the need for wearables. Wake up to a detailed report each morning that includes time spent in each sleep stage, heart rate (HR), heart rate variability (HRV), and more.',
	cta: {
		text: 'Learn more',
		href: '/pod-cover',
	},
	cardComponent: VitalsWidget,
	hint: {
		icon: 'ChartLight',
		text: 'Monitoring your sleep patterns and heart metrics gives you daily feedback on your sleep and physical fitness, allowing you to see improvements and irregularities.',
	},
}

const Alarm: CardProps = {
	supertitle: 'Alarm',
	title: 'Wakes you up gently',
	subtitle: 'Autopilot wakes you up with a gentle vibration and thermal alarm. Wake up feeling refreshed every morning, without disturbing your partner.',
	cta: {
		text: 'Learn more',
		href: '/pod-cover',
	},

	hint: {
		icon: 'AlarmLight',
		text: 'Gentle wake-ups can reduce cortisol spikes, easing stress and promoting a balanced and relaxed start to the day.',
	},
	cardComponent: AlarmWidget,
}

const Elevation: CardProps = {
	supertitle: 'Elevation',
	title: 'Adjusts to your ideal elevation',
	subtitle: 'The Pod adjusts your body to reduce back pressure and improve comfort in bed. Take your sleep to the next level with Autopilot’s automatic elevation adjustments*.',
	cta: {
		text: 'Learn more',
		href: '/pod-cover',
	},

	hint: {
		icon: 'AlarmLight',
		text: 'Sleeping at an incline has shown to reduce back pain, improve blood circulation, acid reflux symptoms, and prevent swelling.',
	},

	cardComponent: ElevationWidget,
	// badge: 'New',
	footnote: '*Available with Pod 4 Ultra only',
}

const Snoring: CardProps = {
	supertitle: 'Snoring',
	title: 'Detects and mitigates snoring',
	subtitle: 'When snoring is detected, Autopilot automatically elevates your head to improve breathing*. Autopilot has been clinically proven to reduce snoring by up to 45%.',
	cta: {
		text: 'Learn more',
		href: '/pod-cover',
	},

	hint: {
		icon: 'AlarmLight',
		text: 'Reducing snoring correlates with improved sleep quality and restoration.',
	},
	cardComponent: SnoringWidget,
	// badge: 'New',
	footnote: '*Available with Pod 4 Ultra only',
}
