import { CSSProperties, FC, ReactNode, useRef } from 'react'
import styles from './TruemedIntraPageComponent.module.scss'
import { TruemedIntraPageComponentProps } from './TruemedIntraPageComponent.types'
import { observer } from 'mobx-react'
import { Button } from 'components/Phantom/Button'
import { TruemedBlueLogo } from 'components/_const_assets/truemedLogo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { preSelectablePaymentMethodsForCurrency } from 'components/Checkout2023/Selections/PaymentTypeSelection'
import cx from 'classnames'
import { amClickedButton } from 'events/amplitude'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

type PartialProps = Partial<TruemedIntraPageComponentProps>
export const TruemedIntraPageComponentConnected: FC<PartialProps> = observer((props) => {
	const { currency } = useRootStore().priceStore
	const availablePaymentMethods = preSelectablePaymentMethodsForCurrency(currency)
	const currencyHasTruemed = availablePaymentMethods.some((it) => it.id === 'truemed')

	const showComponent = currencyHasTruemed

	if (!showComponent) return null
	return <TruemedIntraPageComponent {...props} />
})

export const TruemedIntraPageComponent: FC<TruemedIntraPageComponentProps> = (props) => {
	const { dark } = props

	let mainCta = dark ? (
		<Button.White
			id={'truemed-intra-cta'}
			href={'/product/pod-cover'}
			animateOnScroll
		>
			Shop the sale
		</Button.White>
	) : (
		<Button.Dark
			id={'truemed-shop-now'}
			href={'/product/pod-cover'}
			animateOnScroll
		>
			Shop the sale
		</Button.Dark>
	)

	if (props.anchorHref) {
		mainCta = (
			<a href={props.anchorHref}>
				<Button.White
					id={'truemed-shop-now'}
					onClick={() => {
						amClickedButton('Truemed CTA', 'truemed-shop-now')
					}}
					animateOnScroll
				>
					Shop now
				</Button.White>
			</a>
		)
	}

	return (
		<section
			className={styles.container}
			style={
				{
					'--bg-color': dark ? '#141414' : '#fff',
					'--text-color': dark ? '#fff' : '#000',
					'--learn-more-color': dark ? '#fff' : '#1862ff',
				} as CSSProperties
			}
		>
			<header>
				<Type.Headline3
					as={'h2'}
					className={styles.header}
					animateOnScroll
				>
					Save an average of 30% with FSA/HSA
				</Type.Headline3>
				<Type.Body2 className={styles.subheader}>Payment method available at checkout</Type.Body2>
				<div className={styles.ctas}>
					{mainCta}
					<Button.TextLink
						id={'truemed-learn-more'}
						href={'/truemed/'}
						className={styles.learn_more}
						animateOnScroll
					>
						Learn more
					</Button.TextLink>
				</div>
			</header>

			<article>
				<h3 className={'vh'}>Truemed Details</h3>
				<ul className={cx(styles.details_list, { [styles.details_list_dark]: dark })}>
					<Detail
						index={0}
						title={
							<>
								The Pod is HSA/FSA eligible with <TruemedBlueLogo color={dark ? '#fff' : '#000'} />
							</>
						}
						text={'Purchase the Pod with pre-tax dollars, and get tax-free reimbursements'}
					/>
					<Detail
						index={1}
						title={'Use it before it expires'}
						text={'Contributions from signing up for an FSA must be used by a specific annual deadline'}
					/>
					<Detail
						index={2}
						title={'Invest in your sleep'}
						text={'Improve your sleep with the Pod, and get up to one hour more of sleep each night'}
					/>
				</ul>
			</article>
		</section>
	)
}

export const Detail: FC<{ title: ReactNode; text: ReactNode; index: number }> = (props) => {
	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref, { delay: props.index * 0.1 })
	return (
		<li
			key={props.index}
			ref={ref}
		>
			<Type.Headline5>{props.title}</Type.Headline5>
			<Type.Body2>{props.text}</Type.Body2>
		</li>
	)
}
